//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.PageNavLinks, .PageNavSubLinks {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 58px;
  padding: 1px;
  border-radius: $small-radius;
  a, span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 0 10px;
    font-size: $big-size;
    opacity: 0.75;
    &.active {
      opacity: 1;
      font-weight: bold;
      border-top: 3px solid transparent;
      border-bottom: 3px solid $additional-color;
    }
  }
}

.PageNavLinks {
  background-color: $primary-color;
  a, span {
    color: white;
  }
}

.PageNavSubLinks {
  background: $white-color;
  color: $primary-color;
  border: 1px solid $primary-color;
  a {
    color: $primary-color;
  }
}
