//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.SectionTabs {
  position: relative;
  width: 295px;
  min-width: 295px;
  padding: 40px 1px;
  background-color: $primary-color;

  .height {
    height: 42px;
  }
  .tab {
    display: flex;
    align-items: center;
    padding: 0 36px;
    font-size: $big-size;
    color: white;
    opacity: 0.75;
    border-left: 3px solid transparent;
    &:last-child {
      border-bottom: 1px solid transparent;
    }
    &.shifted {
      padding-left: 52px!important;
    }
    &.active {
      border-left: 3px solid white;
      opacity: 1;
      font-weight: bold;
    }
  }

}

.linksSectionTabs {
  display: grid!important;
}

.customLink {
  width: 260px;
  word-break: break-all;
}

.subTabWrapper {
  display: grid;
  grid-row-gap: 15px;

  .tab {
    padding-left: 16px!important;
    margin-left: 40px;
  }
}

.navWrapper {
  display: grid!important;
  padding: 15px 36px!important;
  a:nth-child(2) {
    margin-top: 20px;
  }
  a {
    padding: 10px 36px!important;
  }
}
