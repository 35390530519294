//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.DatePicker {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 38px;
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #4a4a4a;
  &.disabled {
    cursor: not-allowed;
  }
  &.borderless {
    border: 1px solid transparent;
    border-radius: 0;
  }
  &.transparent {
    background-color: transparent;
  }
  
  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: inherit;
    color: inherit;
    font-size: inherit;
    .value {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 10px;
      height: 100%;
      font-size: $middle-size;
      color: $primary-color;
      overflow: hidden;
      white-space: nowrap;
      background-color: transparent;
      border: none;
      &::placeholder{
        color: #9b9b9b;
      } 
    }
    .xIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 7px;
        height: 7px;
        margin-right: 3px;
      }
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 1;
      margin-right: 10px;

      img {
        width: 6px;
        height: 6px;
      }
    }
  }
  .popup {
    position: absolute;
    top: 38px;
    z-index: 1;
    width: 384px;
  }
}
.error {
  position: absolute;
  color: #D0021B;
  font-size: 10px;
  left: 0;
  bottom: -12px;
}
