//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.mainPage {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;

  padding: 0 30px 0 10px;
}
.wrapper {
  padding: 10px;
  border: 1px solid #d8d8d8;
  background-color: #fff;

  height: calc(100% - 90px);
}

.formSearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.sectionSearch {
  margin-top: 20px;
  height: calc(100% - 68px);
}

.resultCardList {
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 10px;
  .resultCardItem + .resultCardItem {
    margin-top: 10px;
  }
  margin-bottom: 10px;
}

.resultCardItem {
  padding: 30px;
  border: 1px solid gray;
  color: black;
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.resultList {
  padding: 10px 20px;
}

.resultItem {
  list-style-type: decimal;
}

.wrapperBtn {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 0;
}
.wrapperList{
  padding-right: 5px;
}

.error {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #d0021b;
  z-index: 1;
}

.skeletonLoader{
  div+div{
    margin-top: 10px;
  }
}

.skeletonLoaderPagination{
  margin-top: 10px;
  padding-right: 5px;
}