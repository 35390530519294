//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.Card,
.Marck,
.Wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between
}

.Content{
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
}

.Marck{
  span + span{
    margin-left: 10px;
  }
}

.Card{
  border: 1px solid #d8d8d8;
  background-color: #fff;
  width: 100%;
  height: 180px;
}

.Text{
  margin-top: 10px;
}

.Result{
  margin-left: 50px;
  span + span{
    margin-top: 10px;
  }
}