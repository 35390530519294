//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.title {
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
  background: #FAFAFA;
  color: #4A4A4A;
  font-size: 13px;
  height: 38px;
  padding-left: 10px;
}

.wrapper {
  display: grid;
  row-gap: 10px;
  width: 100%;
}


.field {
  display: grid;
  grid-template-columns: 150px 1fr;
  align-items: center;
}

.fields {
  margin: 10px;
}

.accordion_item {
  width: 100%;
  position: relative;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}

.label {
  color: #7d7d7d;
  font-size: 12px;
}

.icon {
  width: 10px;
  height: 10px;
  background: $black-color;
  border-radius: 50%;
}
