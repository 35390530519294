//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
  height: 89px;
  font-size: 13px;
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  color: #4a4a4a;
  &.borderless {
    border: 1px solid transparent;
    border-radius: 0;
  }
  &.transparent {
    background-color: transparent;
  }
  textarea {
    width: 100%;
    height: 100%;
    font-size: $middle-size;
    background-color: inherit;
    border: none;
    resize: none;
    color: $primary-color;
    padding: 10px;
    &::placeholder {
      color: #9b9b9b;
    }
    &:disabled {
      cursor: not-allowed; 
    }
  }
}
.error {
  position: absolute;
  color: #D0021B;
  font-size: 10px;
}

.overflow {
  overflow: hidden;
}
