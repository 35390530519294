//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.tabs {
  display: grid;
  grid-column-gap: 10px;
  width: max-content;
  border-bottom: 1px solid $additional-color;
}

.tabs_wrapper {
  display: grid;
  justify-content: center;
}

.tab {
  color: $secondary-color;
  background: $grey-color_2;
  padding: 8px 14px;
  border-radius: 4px;
  text-align: center;
  display: block;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  &.active {
    background-color: $additional-color;
    color: $white-color;
    font-weight: 600;
  }
}
