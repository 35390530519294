//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.Spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .circle {
    display: inline-block;
    &::after {
      content: " ";
      display: block;
      border-radius: 50%;
      border: 5px solid;
      animation: circle 1.2s linear infinite;
    }
  }
  
  .extra-small {
    width: 20px;
    height: 20px;
    &::after {
      width: 18px;
      height: 18px;
      border: 1px solid;
    }
  }

  .small {
    width: 100px;
    height: 100px;
    &::after {
      width: 90px;
      height: 90px;
    }
  }

  .big {
    width: 200px;
    height: 200px;
    &::after {
      width: 190px;
      height: 190px;
    }
  }

  .light {
    &::after {
      border-color: white transparent white transparent;
    }
  }

  .dark {
    &::after {
      border-color: #9b9b9b transparent #9b9b9b transparent;
    }
  }

  @keyframes circle {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


}
