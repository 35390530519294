//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.fields {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  grid-column-gap: 240px;
  margin-top: 40px;
  .field {
    display: grid;
    grid-template-columns: max-content 1fr;
    border-bottom: 1px solid $grey-color;
    padding: 15px 10px;

    &.dragging {
      background: $grey-color_2;
    }
    .name {
      width: 250px;
      word-break: break-word;
      font-size: $big-small-size;
      color: $primary-color;
    }

    .type {
      font-size: $big-small-size;
      color: $dark-grey-color;
    }

    .actions {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      justify-content: center;
      grid-column-gap: 24px;
      .button {
        font-size: $big-small-size;
        background: transparent;
        border: 0;
        outline: none;
      }

      .edit_button {
        color: $additional-color;
      }

      .delete_button {
        color: $negative-color;
      }
    }

  }
}
