//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
}

.input {
  position: relative;
  display: flex;
  align-items: center;
  height: 38px;
  background-color: #f5f5f5;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &.borderless {
    border-color: transparent;
    border-radius: 0;
  }
  &.transparent {
    background-color: transparent;
  }
}

.values {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 12px;
  height: 100%;
  font-size: $middle-size;
  color: $primary-color;
  overflow: hidden;
  white-space: nowrap;
  &.empty {
    color: #9b9b9b;
  }
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1;
  margin-right: 10px;

  img {
    width: 6px;
    height: 6px;
  }
}

.options {
  position: absolute;
  left: 0;
  top: 38px;
  z-index: 5;
  width: 100%;  
  overflow-y: auto;
  background-color: #F1EFEE;
  box-shadow: 0px 2px 23px #E4E6E9;
}

.option {
  display: flex;
  align-items: center;
  height: 38px;
  background-color: white;
  margin-bottom: 1px;
  overflow: hidden; 
  white-space: nowrap;
  padding: 0 12px;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    background-color: #f5f4f3;
  }
}


.errorMessage {
  position: absolute;
  color: #D0021B;
  font-size: 10px;
}
