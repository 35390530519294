//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.Table {
  position: relative;


  .cleanFilters {
    width: 100%;
    z-index: 10;
    text-align: right;
    padding: 10px 0;
    padding-right: 10px;
    right: 0;
    font-size: 13px;
    font-weight: bold;
    color: #4a4a4a;

    span {
      cursor: pointer;
    }
  }

  .tableBarWrapper {
    background: $grey-color;
    z-index: 11;
    right: 0;
  }
  .tableBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    background-color: white;
    border: 1px solid #d8d8d8;

    .tableBarLeft {
      flex: 1 1;
      display: flex;
      align-items: center;
      // margin: 0 -10px;
      overflow: hidden;
      & > * {
        margin: 0 10px;
      }

      .tilesWrapper {
        flex: 1 1;
        position: relative;
        z-index: 2;
        height: 58px;

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 10px;
          width: 20px;
          height: 40px;
          background-color: white;
          background: linear-gradient(180deg, white, rgba(255,255,255, .4));
          background: -webkit-linear-gradient(180deg, white, rgba(255,255,255, .4));

          z-index: 1;
        }
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 10px;
          width: 20px;
          height: 40px;
          background-color: white;
          background: linear-gradient(-180deg, white, rgba(255,255,255, .4));
          background: -webkit-linear-gradient(-180deg, white, rgba(255,255,255, .4));
          z-index: 1;
        }

        .tiles {
          display: flex;
          align-items: center;
          height: 60px;
          & > * {
            margin: 0 5px;
            &:first-child {
              margin-left: 20px;
            }
            &:last-child {
              border-right: 20px solid transparent;
            }
          }
        }
      }

    }
    .tableBarRight {
      display: flex;
      align-items: center;
      // margin: 0 -10px;

      & > * {
        margin-right: 10px;
      }
      .importButton {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 12px;
        font-size: 13px;
        color: #292929;
        cursor: pointer;
      }
      .arrowButtons {
        display: flex;
        align-items: center;
        & > *:first-child {
          margin-right: 2px;
        }
      }
    }
  }
  .paginationContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 35px;
    padding: 10px;
  }
}

