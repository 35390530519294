//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, 30%);
  margin-top: 25px;
  margin-bottom: 30px;
  grid-auto-rows: 40px;
}

.inputWrapper {
  display: grid;
  grid-template-columns: max-content 400px;
  grid-column-gap: 10px;
}

.actions {
  display: flex;
  justify-content: center;
  grid-row-gap: 10px;
  border-top: 1px solid $grey-color;
  padding-top: 23px;

  :first-child{
    margin-right: 50px;
    background-color: orange;
  }
}

.items {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr max-content;
}


.no_data {
  text-align: center;
  margin-top: 20px;
  color: $negative-color;
}
