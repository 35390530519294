//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.Tittle{
  font-size: 31px;
  margin: 5px 0;
}
.Info, .SecretCode, .SecretCodeTitle{
  font-size: 19px;
  width: 349px;
}

.SecurityForm{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Input{
  padding: 0 10px;
  height: 40px;
  width: 256px;
  margin-top: 10px;
  font-size: 20px;
}
.SubmitBtn{
  width: 260px;
  height: 50px;
  font-size: 20px;
  background-color: #fff;
  color: #EA8226;
  border: 1px solid #EA8226;
  border-radius: 6px;
  margin-top: 10px;
}


.SecretCode{
  margin-top: 10px;
  text-align: center;
}

.SecretCodeTitle{
  margin-right:5px ;
}

.QRCode{
  margin-top: 5px;
}