//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
	position: relative;
	.input {
		position: relative;
		display: flex;
		align-items: center;
		height: 38px;
		background-color: #f5f5f5;
		border: 1px solid #d8d8d8;
		border-radius: 2px;
		cursor: pointer;
		&.disabled {
			cursor: not-allowed;
		}

		&.borderless {
			border: 1px solid transparent;
			border-radius: 0;
		}
		&.transparent {
			background-color: transparent;
		}
		.linkWrapper {
			padding: 0 5px;
			width: 100%;
			white-space: nowrap;
		}
		.value {
			flex: 1;
			max-height: 76px;
			line-height: 36px;
			font-size: $middle-size;
			color: $primary-color;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
			&.empty {
				color: #9b9b9b;
			}
		}
		.valueOther{
      flex: 1;
      padding: 0 10px;
      height: 36px;
      line-height: 36px;
      font-size: $middle-size;
      color: $primary-color;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
		}
    .linkWrapper.disabled .value:hover{
				text-decoration: none;
			}
		.spinner {
			position: absolute;
			right: 10px;
		}
		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			flex: 0 1;
			margin-right: 10px;

			img {
				width: 6px;
				height: 6px;
			}
		}
	}
	.popup {
		position: absolute;
		left: 0;
		//top: 38px;
		z-index: 9;
		width: 100%;
		overflow-y: auto;
		background-color: #f1efee;
		box-shadow: 0px 2px 23px #e4e6e9;
		.search {
			position: relative;
			height: 42px;
			padding: 2px;
			background-color: white;
			.spinner {
				position: absolute;
				right: 0;
				top: 0;
				width: 42px;
				height: 42px;
			}
		}
		.option {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: 38px;
			background-color: white;
			margin-bottom: 1px;
			font-size: 13px;
			color: #4a4a4a;
			overflow: hidden;
			white-space: nowrap;
			padding: 0 12px;
			&:last-child {
				margin-bottom: 0;
			}
			&.hover {
				&:hover {
					background-color: #f5f4f3;
					cursor: pointer;
				}
			}
		}
	}
}
.xIcon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 12px;
	height: 12px;
}
.modal {
	background: $white-color;
	width: 700px;
	padding: 10px 20px;

	.items {
		display: flex;
		flex-wrap: wrap;
		grid-row-gap: 5px;
		grid-column-gap: 5px;
		border: 1px solid $grey-color;
		padding: 10px;
	}

	.spinner {
		margin-top: 10px;
	}

	li,
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.item {
		display: inline-flex;
		background: $grey-color_2;
		padding: 2px 8px;
		align-items: center;
		.xIcon {
			margin-left: 5px;
			cursor: pointer;
		}
	}

	.popup {
		top: 0;
		.option {
			padding-right: 12px;
		}
	}
	.result {
		margin: 7px 2px;
		.resultItem {
			display: flex;
			align-items: center;
			padding: 2px;
			justify-content: space-between;
			.xIcon {
				cursor: pointer;
			}

			&.hover {
				&:hover {
					background-color: #f5f4f3;
					cursor: pointer;
				}
			}
		}
	}
	.search {
		margin-top: 15px;
		margin-bottom: 5px;
	}

	.actions {
		display: flex;
		justify-content: end;
		margin-top: 20px;
	}
}
