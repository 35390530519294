//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.ColumnFilterDropdown {
  position: relative;
  .popup {
    position: absolute;
    right: -10px;
    top: 50px;
    width: 265px;
    overflow-y: auto;
    background-color: #f1efee;
    box-shadow: 0px 2px 23px #e4e6e9;
    z-index: 1010;
    .search {
      height: 42px;
      padding: 2px;
      background-color: white;
    }
    .column {
      display: flex;
      align-items: center;
      height: 38px;
      background-color: white;
      margin-bottom: 1px;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 12px;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background-color: #f5f4f3;
      }
    }
  }

  .action_wrapper {
    width: 100%;
    background: white;
    text-align: center;

  }

  .save_button{
    background: #4a4a4a;
    color: white;
    border: 0;
    padding: 10px 25px;
  }
}
