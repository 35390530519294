//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.TableRuleTile {
  position: relative;

  .bar {
    position: relative;
    min-width: 60px;
    height: 24px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    overflow: hidden;
    transition: all 0.2s;

    &:hover {
      border: 1px solid #d8d8d8;
      .opener {
        right: 0;
      }
    }

    &.active {
      background-color: #f5f5f5;
      border: 1px solid #d8d8d8;
      .opener {
        background-color: #f5f5f5;
      }
    }

    cursor: pointer;
    .text {
      display: flex;
      align-items: center;
      padding: 0 10px;
      height: 100%;
      font-size: 13px;
      color: #4a4a4a;
      white-space: nowrap;
      .name {
        overflow: hidden;
        white-space: nowrap;
        max-width: 200px;
        text-overflow: ellipsis;
      }
    }

    .opener {
      position: absolute;
      right: -24px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 100%;
      transition: all 0.2s;
      background-color: white;
      border-left: 1px solid #d8d8d8;
      border-right: 1px solid transparent;
    }
  }
}
