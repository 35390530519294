//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.grid {
  display: grid;
  grid-template-columns: max-content 1fr 1fr;
  justify-content: space-between;
}

.column {
  display: grid;
  grid-template-rows: repeat(7, max-content);
  grid-row-gap: 15px;
  margin-right: 100px;
}

.dynamic_field {
  display: grid;
  justify-content: end;
  grid-template-columns: 1fr 1fr;
  margin-right: 170px;
}

.row{
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 215px 1fr;
}

.checkbox_item {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  span {
    color: $dark-grey-color;
  }
}

.AuthWrapper{
  margin-top: 20px;
}

.AuthTitle{
  margin-bottom: 10px;
}


.statusActive{
  color: chartreuse;
}

.statusNotActive{
  color: red;
}