//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
  min-height: calc(100vh - 280px);
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
}
.content {
  display: flex;
}
.variables {
  width: 400px;
  font-size: 13px;
  padding-right: 100px;
}
.variable {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &Name {
    color: #9b9b9b;
  }
  &Value {
    color: rgb(74, 74, 74);
  }
}
.template {
  border-left: 1px solid #d8d8d8;
  padding-left: 100px;
}
.editor {
  width: 756px;
}
