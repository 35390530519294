//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


$cAccent: #3978ef;
$cBlack: #4a4a4a;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);

.accordion_list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: grid;
  grid-row-gap: 18px;
  &__item {
    border: 1px solid #dadada;
    border-radius: 8px;
  }
}

.accordion_item {
  $self: &;
  &__opened {
    #{ $self }__icon {
      transform: rotate(180deg);
    }

    #{ $self }__inner {
      max-height: 1000rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }

    #{ $self }__content {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }

  &__line {
    display: block;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    z-index: 2;
    position: relative;
    cursor: pointer;
  }

  &__title {
    font-size: 20px;
    margin: 0;
    font-weight: 400;
    color: $cBlack;
  }

  &__icon {
    width: 8px;
    height: 5px;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.6;
    background-position: 3px;
    margin: 5px 0 0 5px;
  }

  &__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    padding: 0 24px 40px;
  }

  &__paragraph {
    margin: 0;
    font-size: 1rem;
    color: $cGray;
    font-weight: 300;
    line-height: 1.3;
  }

  &__categories {
    display: grid;
    grid-column-gap: 8px;
    margin: 8px 0 24px;


    &__category {
      padding: 8px 14px;
      cursor: pointer;
      &__active {
        background: #E6E6F6!important;
      }
      &__selected {
        color: #4a4a4a;
        background: #e6e6e6;
        padding: 8px 14px;
        border-radius: 4px;
      }
      &_cross {
        cursor: pointer;
        width: 8px;
        height: 8px;
        margin-left: 10px;
      }
      &__removed {
        color: #9b9b9b;
        border: 1px dashed $additional-color;
      }
      &_tick {
        cursor: pointer;
        width: 10px;
        height: 10px;
        margin-left: 10px;
      }
    }
  }

  &__subTitle {
    color: #4a4a4a;
    font-size: 14px;
    display: inline-block;
    font-weight: bold;
  }
}

.accordion_item__subtab {
  display: flex;
  position: relative;
  &__item {
    opacity: .6 !important;
    cursor: pointer;
    padding: 0 25px 0 18px!important;
    &_cross, &_tick {
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      width: 10px;
    }
    &_active {
      color: white !important;
      position: relative;

      &:after {
        content: '';
        display: block;
        height: 2px;
        width: 91%;
        background: white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        right: 0;
        bottom: -6px;
        cursor: pointer;
      }
    }
  }
}

.pageNavLinks_role_manager {
  span {
    padding: 0 25px 0 18px!important;
  }
}

.accordion_item_excepts {
  &_block_title {
    color: #4A4A4A;
    margin: 16px 0 8px 0;
  }

  &_actions {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-items: center;
  }
}

.accordion_item_excepts_block {
  margin-bottom: 24px;
}

.accordion_item__excepts_without_label {
  color: #9B9B9B;
}

.accordion_item_excepts_block_add_button {
  color: #9B9B9B;
  border: 1px solid #DADADA;
  padding: 8px 10px;
  width: max-content;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 4px;
  img {
    margin-right: 6px;
  }
}

.disabled_fields {
  display: flex;
  flex-wrap: wrap;
  width: 800px;
  div {
    margin: 4px;
  }

  img {
    margin-left: 8px;
    cursor: pointer;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 10px 30px;
}

.item {
  display: grid;
  grid-template-columns: 180px 20px;
}

.noDataInCategory {
  padding: 10px;
  color: $negative-color;
  margin-top: 14px;
}

.title {
  font-size: 18px;
}

.fields {
  display: grid;
  grid-row-gap: 5px;
}


.actions {
  display: flex;
  justify-content: flex-end;
  grid-column-gap: 10px;
  margin: 20px
}
