//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  .contentWrapper {
    display: flex;
    & > * {
      margin-right: 80px;
      &:last-child {
        margin-right: 0;
      }
    }
    .columnWrapper {
      display: flex;
      flex-direction: column;
      margin-top: -7px;
      & > * {
        margin: 7px 0;
      }
      .inputWrapper {
        position: relative;
        width: 410px;
        height: 38px;
        & > * {
          position: absolute;
          &:nth-child(2) {
            left: 150px;
          }
          &:nth-child(3) {
            left: 285px;
          }
          &:nth-child(4) {
            left: 330px;
          }
        }
      }
    }
  }
}
