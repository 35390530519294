//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.forbidden_page_wrapper {
  background: $black-color;
  height: 100vh;
}

.staff {
  animation: strike 0.5s 0.5s cubic-bezier(1, 0.25, 1, 1) both;
  bottom: 15vh;
  height: 100vh;
  left: 50%;
  position: absolute;
  transform: translate(15%, 0);
  width: 200px;
}
.shine {
  animation: flash 2s 1s ease;
  background: #fff;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.forbidden_page {
  animation-name: shake;
  animation-iteration-count: 20;
  animation-timing-function: ease;
  animation-delay: 1s;
  animation-duration: 0.1s;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-100%, -50%);
  width: 150px;

  * {
    fill: #fff;
    stroke: #fff;
  }

  @media (min-width: 768px) {
    width: 300px;
  }
}


.go_home_button {
  position: absolute;
  z-index: 1;
  color: white;
  left: 42%;
  bottom: 25%;
  transform: translate(-50%, -50%);
  animation: flash 2s 1s ease;
}



@keyframes flash {
  0% {
    opacity: .85;
  }
  50% {
    opacity: .5;
  }
  75%, 100% {
    opacity: 0
  }
}

@keyframes strike {
  from {
    transform:  translate(15%, -100%);
  }
}
@keyframes shake {
  0%, 50%, 100% {
    transform:  translate(-100%, -50%)
  }
  25% {
    transform: translate(-102%, -52%)
  }
  75%
  {
    transform: translate(-98%, -48%)
  }
}
