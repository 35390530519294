//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


$cAccent: #3978ef;
$cBlack: #4a4a4a;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);

.accordion_list {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: grid;
  grid-row-gap: 18px;
  &__item {
    border: 1px solid #dadada;
    border-radius: 8px;
    list-style: none;
  }
}

.checkbox_item {
  display: flex;
  align-items: center;
  grid-column-gap: 10px;
  span {
    color: $dark-grey-color;
  }
}

.accordion_item {
  $self: &;
  &__opened {
    #{ $self }__icon {
      transform: rotate(180deg);
    }

    #{ $self }__inner {
      max-height: 100rem;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: max-height;
    }

    #{ $self }__content {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.2s;
      transition-timing-function: ease-in-out;
      transition-duration: 0.2s;
      transition-property: opacity, transform;
    }
  }

  &__line {
    padding: 16px 24px;
    align-items: center;
    z-index: 2;
    position: relative;
    cursor: pointer;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    &_name {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }

  &__status {
      color: #9B9B9B;
  }

  &__title {
    font-size: 20px;
    margin: 0;
    font-weight: 400;
    color: $cBlack;
  }

  &__icon {
    width: 8px;
    height: 5px;
    transition: transform 0.3s ease-in-out;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.6;
    background-position: 3px;
    margin: 5px 0 0 5px;
  }

  &__inner {
    max-height: 0;
    overflow: hidden;
    text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
    transition-duration: 0.5s;
    transition-property: max-height;
    z-index: 1;
    position: relative;
  }

  &__content {
    opacity: 0;
    transform: translateY(-1rem);
    transition-timing-function: linear, ease;
    transition-duration: 0.1s;
    transition-property: opacity, transform;
    transition-delay: 0.5s;
    padding: 0 24px 40px;
  }

  &__excepts {
    display: grid;
    grid-row-gap: 24px;
    &_without_label {
      color: #9B9B9B;
    }
    &_block {
      display: flex;
      flex-wrap: wrap;
      grid-row-gap: 10px;
      &__item {
        padding: 8px 14px;
        margin-right: 8px;
        &__selected {
          color: #4a4a4a;
          background: #e6e6e6;
          padding: 8px 14px;
          border-radius: 4px;

          img {
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
    }
    &_except {
      display: grid;
      align-items: center;
      color: #4A4A4A;

      &__maintainer {
        display: flex;
        align-items: center;
          span {
            display: inline-block;
            margin-right: 5px;
          }
      }

      &__title {
        margin-bottom: 10px;
      }
      &__actions {
        display: grid;
        //grid-template-columns: repeat(3, max-content);
        grid-column-gap: 8px;
        align-items: center;
      }
      &_add_button {
        color: #9B9B9B;
        border: 1px solid #DADADA;
        padding: 8px 10px;
        width: max-content;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 4px;
        img {
          margin-right: 6px;
        }
      }
    }
  }
}
