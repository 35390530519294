//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 215px 1fr;
}

.wrapList {
  border: 1px solid #d8d8d8;
  background-color: $grey-color;
  padding: 5px;
}
.list{
  height: 78px;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  height: 38px;

  border: 1px solid $dark-grey-color;
  background-color: #f1efee;
  box-shadow: 0px 2px 23px #e4e6e9;

  padding: 5px;
  margin-bottom: 1px;
  margin-right: 5px;
}

.text {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 13px;
  color: #4a4a4a;
  white-space: nowrap;
  line-height: 1;
}

.crossButton,
.plusButton {
  background-color: transparent;
  border: none;
}
.wrapQuestionInput{
  position: relative;

  margin-top: 5px;
}
.plusButton{
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 38px;
}