//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1efee;

  .content {
    width: 350px;

    .title {
      color: rgb(87 85 85 / 95%);
      font-family: Roboto, sans-serif;
      font-size: 187px;
      font-weight: 100;
      text-align: center;
    }

    .titleBold {
      color: $secondary-color;
      font-size: 25px;
      font-weight: normal;
      text-align: center;
      margin-bottom: 15px;
    }

    .text {
      color: rgba(134, 126, 126, 0.9);
      font-size: 16px;
      text-align: center;
      margin-bottom: 25px;
    }

    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    button {
      width: 165px;
      height: 40px;
      border: none;
      font-size: 12px;
      border-radius: 8px;
      text-shadow: 0 0 black;
      color: $light-grey-color;
      background-color: $additional-color;
    }
  }
}
