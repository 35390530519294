//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.InputLabel {
  display: flex;
  align-items: center;
  //height: 38px;
  font-size: $middle-size;
  color: $primary-color;
  word-break: break-word;
  padding-right: 20px;
;
}

.mark {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 50%;
  padding: 3px 4px;
  margin-left: 3px;
  width: 11px;
}

.height {
  height: 38px
}
