//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$grey-color: #f5f5f5;
$grey-color_2: #e6e6e6;
$dark-grey-color: #9B9B9B;
$light-grey-color: #fafafa;
$red-color: red;
$black-color: #000000;

//Font-sizes
$ultra_small-size: 11px;
$middle_small-size: 12px;
$small-size: 13px;
$big-small-size: 14px;
$middle-size: 15px;
$big-size: 17px;
$ultra-big-size: 20px;

//Border-radius
$small-radius: 4px;


.wrapper {
  position: relative;
  &>* {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.inputWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonWrapper {
  display: grid;
  grid-row-gap: 10px;
  justify-content: center;
  align-items: center;
  height: 70px;
}

.buttonSecondary {
  width: 80px;
  height: 30px;
  color: #9B9B9B;
  background: transparent;
  border: 1px solid transparent;
  font-size: 13px;
}

.buttonSave {
  width: 80px;
  height: 30px;
  font-size: 13px;
  text-transform: uppercase;
  color: white;
  background-color: #4A4A4A;
  border: none;
}

.spinnerWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(74, 74, 74, 0.3);
}
